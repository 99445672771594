export const ANNOUNCE_HEAD_AND_SD_ONLY = 'announce_head_and_sd_only';
export const API = 'api';
export const BLOB_DOWNLOAD_TIMEOUT = 'blob_download_timeout';
export const BLOB_LRU_CACHE_SIZE = 'blob_lru_cache_size';
export const BLOCKCHAIN_NAME = 'blockchain_name';
export const CACHE_TIME = 'cache_time';
export const COIN_SELECTION_STRATEGY = 'coin_selection_strategy';
export const COMMENT_SERVER = 'comment_server';
export const COMPONENTS_TO_SKIP = 'components_to_skip';
export const CONCURRENT_BLOB_ANNOUNCERS = 'concurrent_blob_announcers';
export const CONCURRENT_REFLECTOR_UPLOADS = 'concurrent_reflector_uploads';
export const CONFIG = 'config';
export const DATA_DIR = 'data_dir';
export const DOWNLOAD_DIR = 'download_dir';
export const DOWNLOAD_TIMEOUT = 'download_timeout';
export const FIXED_PEER_DELAY = 'fixed_peer_delay';
export const KNOWN_DHT_NODES = 'known_dht_nodes';
export const LBRYUM_SERVERS = 'lbryum_servers';
export const MAX_CONNECTIONS_PER_DOWNLOAD = 'max_connections_per_download';
export const MAX_KEY_FEE = 'max_key_fee';
export const DEFAULT_WALLET = 'default_wallet';
export const NETWORK_INTERFACE = 'network_interface';
export const NODE_RPC_TIMEOUT = 'node_rpc_timeout';
export const PEER_CONNECT_TIMEOUT = 'peer_connect_timeout';
export const REFLECT_STREAMS = 'reflect_streams';
export const REFLECTOR_SERVERS = 'reflector_servers';
export const S3_HEADERS_DEPTH = 's3_headers_depth';
export const SAVE_BLOBS = 'save_blobs';
export const SAVE_FILES = 'save_files';
export const SHARE_USAGE_DATA = 'share_usage_data';
export const SPLIT_BUCKETS_UNDER_INDEX = 'split_buckets_under_index';
export const STREAMING_GET = 'streaming_get';
export const STREAMING_SERVER = 'streaming_server';
export const TCP_PORT = 'tcp_port';
export const TRACK_BANDWIDTH = 'track_bandwidth';
export const UDP_PORT = 'udp_port';
export const USE_UPNP = 'use_upnp';
export const WALLET_DIR = 'wallet_dir';
export const WALLETS = 'wallets';
