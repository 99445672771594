// Refined from
// https://github.com/ctrl-freaks/freezeframe.js/tree/master/packages/freezeframe/src

// Names match with constants in styles.scss
export const classes = {
  SELECTOR: '.freezeframe',
  CONTAINER: 'ff-container',
  IMAGE: 'ff-image',
  CANVAS: 'ff-canvas',
  READY: 'ff-ready',
  INACTIVE: 'ff-inactive',
  ACTIVE: 'ff-active',
  CANVAS_READY: 'ff-canvas-ready',
  RESPONSIVE: 'ff-responsive',
  VISIBLE: 'ff-visible',
};
