// @flow

const buildCDNUrl = (path: string) => `https://static.odycdn.com/emoticons/${path}`;

const buildEmote = (name: string, path: string) => ({
  name: `:${name}:`,
  url: buildCDNUrl(path),
});

const getEmotes = (px: string, multiplier: string) => [
  buildEmote('love_1', `${px}/Love${multiplier}.png`),
  buildEmote('love_2', `${px}/Love%202${multiplier}.png`),
  buildEmote('smile_1', `${px}/smile${multiplier}.png`),
  buildEmote('smile_2', `${px}/smile%202${multiplier}.png`),
  buildEmote('laughing_1', `${px}/Laughing${multiplier}.png`),
  buildEmote('laughing_2', `${px}/Laughing 2${multiplier}.png`),
  buildEmote('cry_2', `${px}/cry%202${multiplier}.png`),
  buildEmote('cry_4', `${px}/cry%204${multiplier}.png`),
  buildEmote('angry_1', `${px}/angry${multiplier}.png`),
  buildEmote('angry_3', `${px}/angry%203${multiplier}.png`),
  buildEmote('kiss_1', `${px}/kiss${multiplier}.png`),
  buildEmote('surprised', `${px}/surprised${multiplier}.png`),
  buildEmote('ouch', `${px}/ouch${multiplier}.png`),
  buildEmote('confused_2', `${px}/confused${multiplier}.png`),
  buildEmote('what', `${px}/what_${multiplier}.png`),
  buildEmote('sad', `${px}/sad${multiplier}.png`),
  buildEmote('angry_2', `${px}/angry%202${multiplier}.png`),
  buildEmote('cry_1', `${px}/cry${multiplier}.png`),
  buildEmote('cry_3', `${px}/cry%203${multiplier}.png`),
  buildEmote('cry_5', `${px}/cry%205${multiplier}.png`),
  buildEmote('rainbow_puke_1', `${px}/rainbow%20puke${multiplier}-1.png`),
  buildEmote('sleep', `${px}/Sleep${multiplier}.png`),
  buildEmote('thinking_2', `${px}/thinking${multiplier}.png`),
  buildEmote('peace', `${px}/peace${multiplier}.png`),
  buildEmote('no', `${px}/NO${multiplier}.png`),
  buildEmote('block', `${px}/block${multiplier}.png`),
  buildEmote('confirm', `${px}/CONFIRM${multiplier}.png`),
  buildEmote('thumb_down', `${px}/thumb%20down${multiplier}.png`),
  buildEmote('thumb_up_1', `${px}/thumb%20up${multiplier}-1.png`),
  buildEmote('kiss_2', `${px}/kiss%202${multiplier}.png`),
  buildEmote('thinking_1', `${px}/thinking${multiplier}-1.png`),
  buildEmote('angry_4', `${px}/angry%204${multiplier}.png`),
  buildEmote('scary', `${px}/scary${multiplier}.png`),
  buildEmote('alien', `${px}/Alien${multiplier}.png`),
  buildEmote('blind', `${px}/blind${multiplier}.png`),
  buildEmote('bomb', `${px}/bomb${multiplier}.png`),
  buildEmote('brain_chip', `${px}/Brain%20chip${multiplier}.png`),
  buildEmote('confused_1', `${px}/confused${multiplier}-1.png`),
  buildEmote('cooking_something_nice', `${px}/cooking%20something%20nice${multiplier}.png`),
  buildEmote('donut', `${px}/donut${multiplier}.png`),
  buildEmote('eggplant_with_condom', `${px}/eggplant%20with%20condom${multiplier}.png`),
  buildEmote('eggplant', `${px}/eggplant${multiplier}.png`),
  buildEmote('fire_up', `${px}/fire%20up${multiplier}.png`),
  buildEmote('flat_earth', `${px}/Flat%20earth${multiplier}.png`),
  buildEmote('flying_saucer', `${px}/Flying%20saucer${multiplier}.png`),
  buildEmote('heart_chopper', `${px}/heart%20chopper${multiplier}.png`),
  buildEmote('ice_cream', `${px}/ice%20cream${multiplier}.png`),
  buildEmote('idk', `${px}/IDK${multiplier}.png`),
  buildEmote('illuminati_1', `${px}/Illuminati${multiplier}-1.png`),
  buildEmote('illuminati_2', `${px}/Illuminati${multiplier}.png`),
  buildEmote('laser_gun', `${px}/laser%20gun${multiplier}.png`),
  buildEmote('lollipop', `${px}/Lollipop${multiplier}.png`),
  buildEmote('monster', `${px}/Monster${multiplier}.png`),
  buildEmote('mushroom', `${px}/mushroom${multiplier}.png`),
  buildEmote('nail_it', `${px}/Nail%20It${multiplier}.png`),
  buildEmote('pizza', `${px}/pizza${multiplier}.png`),
  buildEmote('rabbit_hole', `${px}/rabbit%20hole${multiplier}.png`),
  buildEmote('rainbow_puke_2', `${px}/rainbow%20puke${multiplier}.png`),
  buildEmote('rock', `${px}/ROCK${multiplier}.png`),
  buildEmote('salty', `${px}/salty${multiplier}.png`),
  buildEmote('slime_down', `${px}/slime%20down${multiplier}.png`),
  buildEmote('smelly_socks', `${px}/smelly%20socks${multiplier}.png`),
  buildEmote('spock', `${px}/SPOCK${multiplier}.png`),
  buildEmote('star', `${px}/Star${multiplier}.png`),
  buildEmote('sunny_day', `${px}/sunny%20day${multiplier}.png`),
  buildEmote('sweet', `${px}/sweet${multiplier}.png`),
  buildEmote('thumb_up_2', `${px}/thumb%20up${multiplier}.png`),
  buildEmote('tinfoil_hat', `${px}/tin%20hat${multiplier}.png`),
  buildEmote('troll_king', `${px}/Troll%20king${multiplier}.png`),
  buildEmote('ufo', `${px}/ufo${multiplier}.png`),
  buildEmote('woodoo_doll', `${px}/woodo%20doll${multiplier}.png`),
  buildEmote('hyper_troll', `${px}/HyperTroll${multiplier}.png`),
  buildEmote('space_chad', `${px}/space%20chad${multiplier}.png`),
  buildEmote('space_doge', `${px}/doge${multiplier}.png`),
  buildEmote('space_green_wojak', `${px}/space%20wojak${multiplier}-1.png`),
  buildEmote('space_julian', `${px}/Space%20Julian${multiplier}.png`),
  buildEmote('space_red_wojak', `${px}/space%20wojak${multiplier}.png`),
  buildEmote('space_resitas', `${px}/resitas${multiplier}.png`),
  buildEmote('space_tom', `${px}/space%20Tom${multiplier}.png`),
  buildEmote('waiting', `${px}/waiting${multiplier}.png`),
];

const buildTwemote = (name: string, path: string, unicode: string) => ({
  name: `:${name}:`,
  url: 'https://static.odycdn.com/emoticons/twemoji/' + path,
  unicode: unicode,
});
const getTwemotes = (category) => {
  switch (category) {
    case 'SMILIES':
      return [
        buildTwemote('grinning', `smilies/grinning.png`, '😀'),
        buildTwemote('smiley', `smilies/smiley.png`, '😃'),
        buildTwemote('smile', `smilies/smile.png`, '😄'),
        buildTwemote('grin', `smilies/grin.png`, '😁'),
        buildTwemote('laughing', `smilies/laughing.png`, '😆'),
        buildTwemote('sweat_smile', `smilies/sweat_smile.png`, '😅'),
        buildTwemote('joy', `smilies/joy.png`, '😂'),
        buildTwemote('rofl', `smilies/rofl.png`, '🤣'),
        buildTwemote('relaxed', `smilies/relaxed.png`, '☺'),

        buildTwemote('blush', `smilies/blush.png`, '😊'),
        buildTwemote('innocent', `smilies/innocent.png`, '😇'),
        buildTwemote('slight_smile', `smilies/slight_smile.png`, '🙂'),
        buildTwemote('upside_down_face', `smilies/upside_down_face.png`, '🙃'),
        buildTwemote('wink', `smilies/wink.png`, '😉'),
        buildTwemote('relieved', `smilies/relieved.png`, '😌'),
        buildTwemote('smiling_face_with_tear', `smilies/smiling_face_with_tear.png`, '🥲'),
        buildTwemote('heart_eyes', `smilies/heart_eyes.png`, '😍'),
        buildTwemote('smiling_face_with_hearts', `smilies/smiling_face_with_hearts.png`, '🥰'),

        buildTwemote('kissing_heart', `smilies/kissing_heart.png`, '😘'),
        buildTwemote('kissing', `smilies/kissing.png`, '😗'),
        buildTwemote('kissing_smiling_eyes', `smilies/kissing_smiling_eyes.png`, '😙'),
        buildTwemote('kissing_closed_eyes', `smilies/kissing_closed_eyes.png`, '😚'),
        buildTwemote('yum', `smilies/yum.png`, '😋'),
        buildTwemote('stuck_out_tongue', `smilies/stuck_out_tongue.png`, '😛'),
        buildTwemote('stuck_out_tongue_closed_eyes', `smilies/stuck_out_tongue_closed_eyes.png`, '😝'),
        buildTwemote('stuck_out_tongue_winking_eye', `smilies/stuck_out_tongue_winking_eye.png`, '😜'),
        buildTwemote('zany', `smilies/zany.png`, '🤪'),

        buildTwemote('raised_eyebrow', `smilies/raised_eyebrow.png`, '🤨'),
        buildTwemote('monocle', `smilies/monocle.png`, '🧐'),
        buildTwemote('nerd_face', `smilies/nerd_face.png`, '🤓'),
        buildTwemote('sunglasses', `smilies/sunglasses.png`, '😎'),
        buildTwemote('star_struck', `smilies/star_struck.png`, '🤩'),
        buildTwemote('partying', `smilies/partying.png`, '🥳'),
        buildTwemote('smirk', `smilies/smirk.png`, '😏'),
        buildTwemote('unamused', `smilies/unamused.png`, '😒'),
        buildTwemote('disappointed', `smilies/disappointed.png`, '😞'),

        buildTwemote('pensive', `smilies/pensive.png`, '😔'),
        buildTwemote('worried', `smilies/worried.png`, '😟'),
        buildTwemote('confused', `smilies/confused.png`, '😕'),
        buildTwemote('slightly_frowning_face', `smilies/slightly_frowning_face.png`, '🙁'),
        buildTwemote('frowning_face', `smilies/frowning_face.png`, '☹'),
        buildTwemote('persevere', `smilies/persevere.png`, '😣'),
        buildTwemote('confounded', `smilies/confounded.png`, '😖'),
        buildTwemote('tired_face', `smilies/tired_face.png`, '😫'),
        buildTwemote('weary', `smilies/weary.png`, '😩'),

        buildTwemote('pleading', `smilies/pleading.png`, '🥺'),
        buildTwemote('cry', `smilies/cry.png`, '😢'),
        buildTwemote('sob', `smilies/sob.png`, '😭'),
        buildTwemote('triump', `smilies/triumph.png`, '😤'),
        buildTwemote('exhaling', `smilies/exhaling.png`, '😮‍💨'),
        buildTwemote('angry', `smilies/angry.png`, '😠'),
        buildTwemote('rage', `smilies/rage.png`, '😡'),
        buildTwemote('symbols_over_mouth', `smilies/symbols_over_mouth.png`, '🤬'),
        buildTwemote('exploding_head', `smilies/exploding_head.png`, '🤯'),

        buildTwemote('flushed', `smilies/flushed.png`, '😳'),
        buildTwemote('face_in_clouds', `smilies/face_in_clouds.png`, '😶‍🌫️'),
        buildTwemote('hot', `smilies/hot.png`, '🥵'),
        buildTwemote('cold', `smilies/cold.png`, '🥶'),
        buildTwemote('scream', `smilies/scream.png`, '😱'),
        buildTwemote('fearful', `smilies/fearful.png`, '😨'),
        buildTwemote('cold_sweat', `smilies/cold_sweat.png`, '😰'),
        buildTwemote('disappointed_relieved', `smilies/disappointed_relieved.png`, '😥'),
        buildTwemote('sweat', `smilies/sweat.png`, '😅'),

        buildTwemote('hugging', `smilies/hugging.png`, '🤗'),
        buildTwemote('thinking', `smilies/thinking.png`, '🤔'),
        buildTwemote('hand_over_mouth', `smilies/hand_over_mouth.png`, '🤭'),
        buildTwemote('yawning', `smilies/yawning.png`, '🥱'),
        buildTwemote('shushing', `smilies/shushing.png`, '🤫'),
        buildTwemote('lying_face', `smilies/lying_face.png`, '🤥'),
        buildTwemote('no_mouth', `smilies/no_mouth.png`, '😶'),
        buildTwemote('neutral_face', `smilies/neutral_face.png`, '😐'),
        buildTwemote('expressionless', `smilies/expressionless.png`, '😑'),

        buildTwemote('grimacing', `smilies/grimacing.png`, '😬'),
        buildTwemote('roll_eyes', `smilies/roll_eyes.png`, '🙄'),
        buildTwemote('hushed', `smilies/hushed.png`, '😯'),
        buildTwemote('frowning', `smilies/frowning.png`, '☹'),
        buildTwemote('anguished', `smilies/anguished.png`, '😧'),
        buildTwemote('open_mouth', `smilies/open_mouth.png`, '😮'),
        buildTwemote('astonished', `smilies/astonished.png`, '😲'),
        buildTwemote('sleeping', `smilies/sleeping.png`, '😴'),
        buildTwemote('drooling_face', `smilies/drooling_face.png`, '🤤'),

        buildTwemote('sleepy', `smilies/sleepy.png`, '😪'),
        buildTwemote('dizzy_face', `smilies/dizzy_face.png`, '😵'),
        buildTwemote('spiral_eyes', `smilies/spiral_eyes.png`, '😵‍💫'),
        buildTwemote('zipper_mouth_face', `smilies/zipper_mouth_face.png`, '🤐'),
        buildTwemote('woozy', `smilies/woozy.png`, '🥴'),
        buildTwemote('nauseated_face', `smilies/nauseated_face.png`, '🤢'),
        buildTwemote('vomiting', `smilies/vomiting.png`, '🤮'),
        buildTwemote('sneezing_face', `smilies/sneezing_face.png`, '🤧'),
        buildTwemote('mask', `smilies/mask.png`, '😷'),

        buildTwemote('face_with_thermometer', `smilies/face_with_thermometer.png`, '🤒'),
        buildTwemote('face_with_head_bandage', `smilies/face_with_head_bandage.png`, '🤕'),
        buildTwemote('money_mouth_face', `smilies/money_mouth_face.png`, '🤑'),
        buildTwemote('cowboy_hat_face', `smilies/cowboy_hat_face.png`, '🤠'),
        buildTwemote('disguised_face', `smilies/disguised_face.png`, '🥸'),
        buildTwemote('smiling_imp', `smilies/smiling_imp.png`, '😈'),
        buildTwemote('imp', `smilies/imp.png`, '👿'),
        buildTwemote('japanese_ogre', `smilies/japanese_ogre.png`, '👹'),
        buildTwemote('japanese_goblin', `smilies/japanese_goblin.png`, '👺'),

        buildTwemote('clown_face', `smilies/clown_face.png`, '🤡'),
        buildTwemote('skull', `smilies/skull.png`, '💀'),
        buildTwemote('alien_1', `smilies/alien_1.png`, '👽'),
        buildTwemote('robot', `smilies/robot.png`, '🤖'),
        buildTwemote('space_invader', `smilies/space_invader.png`, '👾'),
        buildTwemote('santa_claus', `smilies/santa_claus.png`, '🎅'),
        buildTwemote('full_moon_face', `smilies/full_moon_face.png`, '🌝'),
        buildTwemote('new_moon_face', `smilies/new_moon_face.png`, '🌚'),
        buildTwemote('jack_o_lantern', `smilies/jack_o_lantern.png`, '🎃'),
        buildTwemote('moai', `smilies/moai.png`, '🗿'),
        buildTwemote('melting_face', `smilies/melting_face.png`, '🫠'),
      ];
    case 'HANDSIGNALS':
      return [
        buildTwemote('thumbs_up', `handsignals/thumbs_up.png`, '👍'),
        buildTwemote('thumbs_down', `handsignals/thumbs_down.png`, '👎'),
        buildTwemote('raised_hand', `handsignals/raised_hand.png`, '✋'),
        buildTwemote('raised_back_of_hand', `handsignals/raised_back_of_hand.png`, '🤚'),
        buildTwemote('raised_hand_with_fingers_splayed', `handsignals/raised_hand_with_fingers_splayed.png`, '🖐'),
        buildTwemote('waving_hand', `handsignals/waving_hand.png`, '👋'),
        buildTwemote('vulcan_salute', `handsignals/vulcan_salute.png`, '🖖'),
        buildTwemote('rightwards_hand', `handsignals/rightwards_hand.png`, '🫱'),
        buildTwemote('leftwards_hand', `handsignals/leftwards_hand.png`, '🫲'),
        buildTwemote('palm_down_hand', `handsignals/palm_down_hand.png`, '🫳'),
        buildTwemote('palm_up_hand', `handsignals/palm_up_hand.png`, '🫴'),

        buildTwemote('ok_hand', `handsignals/ok_hand.png`, '👌'),
        buildTwemote('pinched_fingers', `handsignals/pinched_fingers.png`, '🤌'),
        buildTwemote('pinching_hand', `handsignals/pinching_hand.png`, '🤏'),
        buildTwemote('victory_hand', `handsignals/victory_hand.png`, '✌'),
        buildTwemote('crossed_fingers', `handsignals/crossed_fingers.png`, '🤞'),
        buildTwemote(
          'hand_with_index_finger_and_thumb_crossed',
          `handsignals/hand_with_index_finger_and_thumb_crossed.png`,
          '🫰'
        ),
        buildTwemote('love_you_gesture', `handsignals/love_you_gesture.png`, '🤟'),
        buildTwemote('sign_of_the_horns', `handsignals/sign_of_the_horns.png`, '🤘'),
        buildTwemote('call_me_hand', `handsignals/call_me_hand.png`, '🤙'),
        buildTwemote('backhand_index_pointing_left', `handsignals/backhand_index_pointing_left.png`, '👈'),
        buildTwemote('backhand_index_pointing_right', `handsignals/backhand_index_pointing_right.png`, '👉'),

        buildTwemote('backhand_index_pointing_up', `handsignals/backhand_index_pointing_up.png`, '👆'),
        buildTwemote('backhand_index_pointing_down', `handsignals/backhand_index_pointing_down.png`, '👇'),
        buildTwemote('middle_finger', `handsignals/middle_finger.png`, '🖕'),
        buildTwemote('index_pointing_up', `handsignals/index_pointing_up.png`, '☝'),
        buildTwemote('index_pointing_at_the_viewer', `handsignals/index_pointing_at_the_viewer.png`, '🫵'),
        buildTwemote('raised_fist', `handsignals/raised_fist.png`, '✊'),
        buildTwemote('oncoming_fist', `handsignals/oncoming_fist.png`, '👊'),
        buildTwemote('left_facing_fist', `handsignals/left_facing_fist.png`, '🤛'),
        buildTwemote('right_facing_fist', `handsignals/right_facing_fist.png`, '🤜'),
        buildTwemote('clapping_hands', `handsignals/clapping_hands.png`, '👏'),
        buildTwemote('raising_hands', `handsignals/raising_hands.png`, '🙌'),

        buildTwemote('heart_hands', `handsignals/heart_hands.png`, '🫶'),
        buildTwemote('open_hands', `handsignals/open_hands.png`, '👐'),
        buildTwemote('palms_up_together', `handsignals/palms_up_together.png`, '🤲'),
        buildTwemote('handshake', `handsignals/handshake.png`, '🤝'),
        buildTwemote('folded_hands', `handsignals/folded_hands.png`, '🙏'),
      ];
    case 'NATURE':
      return [
        buildTwemote('dog', `nature/dog.png`, '🐶'),
        buildTwemote('cat', `nature/cat.png`, '🐱'),
        buildTwemote('mouse', `nature/mouse.png`, '🐭'),
        buildTwemote('hamster', `nature/hamster.png`, '🐹'),
        buildTwemote('rabbit', `nature/rabbit.png`, '🐰'),
        buildTwemote('fox', `nature/fox.png`, '🦊'),
        buildTwemote('wolf', `nature/wolf.png`, '🐺'),
        buildTwemote('bear', `nature/bear.png`, '🐻'),
        buildTwemote('panda', `nature/panda.png`, '🐼'),
        buildTwemote('polar_bear', `nature/polar_bear.png`, '🐻‍❄️'),
        buildTwemote('koala', `nature/koala.png`, '🐨'),

        buildTwemote('tiger', `nature/tiger.png`, '🐯'),
        buildTwemote('lion', `nature/lion.png`, '🦁'),
        buildTwemote('cow', `nature/cow.png`, '🐮'),
        buildTwemote('pig', `nature/pig.png`, '🐷'),
        buildTwemote('boar', `nature/boar.png`, '🐗'),
        buildTwemote('frog', `nature/frog.png`, '🐸'),
        buildTwemote('gorilla', `nature/gorilla.png`, '🦍'),
        buildTwemote('monkey', `nature/monkey.png`, '🐵'),
        buildTwemote('see_no_evil', `nature/see_no_evil.png`, '🙈'),
        buildTwemote('hear_no_evil', `nature/hear_no_evil.png`, '🙉'),
        buildTwemote('speak_no_evil', `nature/speak_no_evil.png`, '🙊'),

        buildTwemote('chicken', `nature/chicken.png`, '🐔'),
        buildTwemote('eagle', `nature/eagle.png`, '🦅'),
        buildTwemote('flamingo', `nature/flamingo.png`, '🦩'),
        buildTwemote('penguin', `nature/penguin.png`, '🐧'),
        buildTwemote('owl', `nature/owl.png`, '🦉'),
        buildTwemote('parrot', `nature/parrot.png`, '🦜'),
        buildTwemote('duck', `nature/duck.png`, '🦆'),
        buildTwemote('swan', `nature/swan.png`, '🦢'),
        buildTwemote('dove', `nature/dove.png`, '🕊'),
        buildTwemote('peacock', `nature/peacock.png`, '🦚'),

        buildTwemote('dodo', `nature/dodo.png`, '🦤'),
        buildTwemote('bird', `nature/bird.png`, '🐦'),
        buildTwemote('raccoon', `nature/raccoon.png`, '🦝'),
        buildTwemote('hedgehog', `nature/hedgehog.png`, '🦔'),
        buildTwemote('sloth', `nature/sloth.png`, '🦥'),
        buildTwemote('bat', `nature/bat.png`, '🦇'),
        buildTwemote('otter', `nature/otter.png`, '🦦'),
        buildTwemote('skunk', `nature/skunk.png`, '🦨'),
        buildTwemote('deer', `nature/deer.png`, '🦌'),
        buildTwemote('camel', `nature/camel.png`, '🐫'),
        buildTwemote('llama', `nature/llama.png`, '🦙'),
        buildTwemote('goat', `nature/goat.png`, '🐐'),
        buildTwemote('giraffe', `nature/giraffe.png`, '🦒'),
        buildTwemote('elephant', `nature/elephant.png`, '🐘'),
        buildTwemote('mammoth', `nature/mammoth.png`, '🦣'),
        buildTwemote('leopard', `nature/leopard.png`, '🐆'),
        buildTwemote('rhinoceros', `nature/rhinoceros.png`, '🦏'),
        buildTwemote('hippopotamus', `nature/hippopotamus.png`, '🦛'),
        buildTwemote('bison', `nature/bison.png`, '🦬'),
        buildTwemote('kangaroo', `nature/kangaroo.png`, '🦘'),
        buildTwemote('horse_face', `nature/horse_face.png`, '🐴'),
        buildTwemote('zebra', `nature/zebra.png`, '🦓'),
        buildTwemote('unicorn', `nature/unicorn.png`, '🦄'),

        buildTwemote('dragon_face', `nature/dragon_face.png`, '🐲'),
        buildTwemote('t-rex', `nature/t-rex.png`, '🦖'),
        buildTwemote('sauropod', `nature/sauropod.png`, '🦕'),
        buildTwemote('snake', `nature/snake.png`, '🐍'),
        buildTwemote('lizard', `nature/lizard.png`, '🦎'),
        buildTwemote('crocodile', `nature/crocodile.png`, '🐊'),
        buildTwemote('turtle', `nature/turtle.png`, '🐢'),
        buildTwemote('whale', `nature/whale.png`, '🐋'),
        buildTwemote('dolphin', `nature/dolphin.png`, '🐬'),
        buildTwemote('seal', `nature/seal.png`, '🦭'),
        buildTwemote('fish', `nature/fish.png`, '🐟'),
        buildTwemote('tropical_fish', `nature/tropical_fish.png`, '🐠'),
        buildTwemote('blowfish', `nature/blowfish.png`, '🐡'),
        buildTwemote('shark', `nature/shark.png`, '🦈'),
        buildTwemote('spiral_shell', `nature/spiral_shell.png`, '🐚'),

        buildTwemote('crab', `nature/crab.png`, '🦀'),
        buildTwemote('lobster', `nature/lobster.png`, '🦞'),
        buildTwemote('shrimp', `nature/shrimp.png`, '🦐'),
        buildTwemote('octopus', `nature/octopus.png`, '🐙'),
        buildTwemote('squid', `nature/squid.png`, '🦑'),

        buildTwemote('snail', `nature/snail.png`, '🐌'),
        buildTwemote('butterfly', `nature/butterfly.png`, '🦋'),
        buildTwemote('bug', `nature/bug.png`, '🐛'),
        buildTwemote('ant', `nature/ant.png`, '🐜'),
        buildTwemote('honeybee', `nature/honeybee.png`, '🐝'),
        buildTwemote('beetle', `nature/beetle.png`, '🪲'),
        buildTwemote('lady_beetle', `nature/lady_beetle.png`, '🐞'),
        buildTwemote('cricket', `nature/cricket.png`, '🦗'),
        buildTwemote('cockroach', `nature/cockroach.png`, '🪳'),
        buildTwemote('spider', `nature/spider.png`, '🕷'),
        buildTwemote('scorpion', `nature/scorpion.png`, '🦂'),
        buildTwemote('mosquito', `nature/mosquito.png`, '🦟'),
        buildTwemote('fly', `nature/fly.png`, '🪰'),
        buildTwemote('worm', `nature/worm.png`, '🪱'),

        buildTwemote('deciduous_tree', `nature/deciduous_tree.png`, '🌳'),
        buildTwemote('evergreen_tree', `nature/evergreen_tree.png`, '🌲'),
        buildTwemote('palm_tree', `nature/palm_tree.png`, '🌴'),
        buildTwemote('cactus', `nature/cactus.png`, '🌵'),
        buildTwemote('herb', `nature/herb.png`, '🌿'),
        buildTwemote('lotus', `nature/lotus.png`, '🪷'),
        buildTwemote('cherry_blossom', `nature/cherry_blossom.png`, '🌸'),
        buildTwemote('tulip', `nature/tulip.png`, '🌷'),
        buildTwemote('rose', `nature/rose.png`, '🌹'),
      ];
    case 'ACTIVITIES':
      return [
        buildTwemote('soccer', `activities/soccer.png`, '⚽'),
        buildTwemote('american_football', `activities/american_football.png`, '🏈'),
        buildTwemote('basketball', `activities/basketball.png`, '🏀'),
        buildTwemote('baseball', `activities/baseball.png`, '⚾'),
        buildTwemote('tennis', `activities/tennis.png`, '🎾'),
        buildTwemote('volleyball', `activities/volleyball.png`, '🏐'),
        buildTwemote('softball', `activities/softball.png`, '🥎'),
        buildTwemote('rugby_football', `activities/rugby_football.png`, '🏉'),
        buildTwemote('ping_pong', `activities/ping_pong.png`, '🏓'),
        buildTwemote('ice_hockey', `activities/ice_hockey.png`, '🏒'),
        buildTwemote('field_hockey', `activities/field_hockey.png`, '🏑'),

        buildTwemote('bowling', `activities/bowling.png`, '🎳'),
        buildTwemote('cricket_game', `activities/cricket_game.png`, '🏏'),
        buildTwemote('lacrosse', `activities/lacrosse.png`, '🥍'),
        buildTwemote('dart', `activities/dart.png`, '🎯'),
        buildTwemote('badminton', `activities/badminton.png`, '🏸'),
        buildTwemote('curling_stone', `activities/curling_stone.png`, '🥌'),
        buildTwemote('boxing_glove', `activities/boxing_glove.png`, '🥊'),
        buildTwemote('martial_arts_uniform', `activities/martial_arts_uniform.png`, '🥋'),
        buildTwemote('golf', `activities/golf.png`, '⛳'),
        buildTwemote('flying_disc', `activities/flying_disc.png`, '🥏'),
        buildTwemote('diving_mask', `activities/diving_mask.png`, '🤿'),

        buildTwemote('pc', `activities/pc.png`, ':pc:'),
        buildTwemote('switch', `activities/switch.png`, ':switch:'),
        buildTwemote('ps5', `activities/ps5.png`, ':ps5:'),
        buildTwemote('xbox', `activities/xbox.png`, ':xbox:'),
        buildTwemote('vr', `activities/vr.png`, ':vr:'),
        buildTwemote('video_game', `activities/video_game.png`, '🎮'),
        buildTwemote('joystick', `activities/joystick.png`, '🕹'),
        buildTwemote('smartphone', `activities/smartphone.png`, '📱'),
        buildTwemote('gameboy', `activities/gameboy.png`, ':gameboy:'),
        buildTwemote('arcade', `activities/arcade.png`, ':arcade:'),
        buildTwemote('slot_machine', `activities/slot_machine.png`, '🎰'),

        buildTwemote('carousel_horse', `activities/carousel_horse.png`, '🎠'),
        buildTwemote('playground_slide', `activities/playground_slide.png`, '🛝'),
        buildTwemote('skateboard', `activities/skateboard.png`, '🛹'),
        buildTwemote('canoe', `activities/canoe.png`, '🛶'),
        buildTwemote('sailboat', `activities/sailboat.png`, '⛵'),
        buildTwemote('roller_skate', `activities/roller_skate.png`, '🛼'),
        buildTwemote('kick_scooter', `activities/kick_scooter.png`, '🛴'),
        buildTwemote('bicycle', `activities/bicycle.png`, '🚲'),
        buildTwemote('motor_scooter', `activities/motor_scooter.png`, '🛵'),
        buildTwemote('motorcycle', `activities/motorcycle.png`, '🏍'),
        buildTwemote('tractor', `activities/tractor.png`, '🚜'),

        buildTwemote('automobile', `activities/automobile.png`, '🚗'),
        buildTwemote('minibus', `activities/minibus.png`, '🚐'),
        buildTwemote('bus', `activities/bus.png`, '🚌'),
        buildTwemote('trolleybus', `activities/trolleybus.png`, '🚎'),
        buildTwemote('train', `activities/train.png`, '🚆'),
        buildTwemote('racing_car', `activities/racing_car.png`, '🏎'),
        buildTwemote('helicopter', `activities/helicopter.png`, '🚁'),
        buildTwemote('airplane', `activities/airplane.png`, '✈'),
        buildTwemote('parachute', `activities/parachute.png`, '🪂'),
        buildTwemote('rocket', `activities/rocket.png`, '🚀'),
        buildTwemote('flying_saucer', `activities/flying_saucer.png`, '🛸'),

        buildTwemote('game_die', `activities/game_die.png`, '🎲'),
        buildTwemote('artist_palette', `activities/artist_palette.png`, '🎨'),
        buildTwemote('fishing_pole', `activities/fishing_pole.png`, '🎣'),
        buildTwemote('magic_wand', `activities/magic_wand.png`, '🪄'),
        buildTwemote('crystal_ball', `activities/crystal_ball.png`, '🔮'),
        buildTwemote('backpack', `activities/backpack.png`, '🎒'),
        buildTwemote('sewing_needle', `activities/sewing_needle.png`, '🪡'),
        buildTwemote('headphone', `activities/headphone.png`, '🎧'),
        buildTwemote('microphone', `activities/microphone.png`, '🎤'),
        buildTwemote('boomerang', `activities/boomerang.png`, '🪃'),
        buildTwemote('bow_and_arrow', `activities/bow_and_arrow.png`, '🏹'),

        buildTwemote('guitar', `activities/guitar.png`, '🎸'),
        buildTwemote('drum', `activities/drum.png`, '🥁'),
        buildTwemote('violin', `activities/violin.png`, '🎻'),
        buildTwemote('trumpet', `activities/trumpet.png`, '🎺'),
        buildTwemote('saxophone', `activities/saxophone.png`, '🎷'),
        buildTwemote('accordion', `activities/accordion.png`, '🪗'),
        buildTwemote('banjo', `activities/banjo.png`, '🪕'),
        buildTwemote('long_drum', `activities/long_drum.png`, '🪘'),
        buildTwemote('postal_horn', `activities/postal_horn.png`, '📯'),
        buildTwemote('musical_keyboard', `activities/musical_keyboard.png`, '🎹'),
        buildTwemote('musical_score', `activities/musical_score.png`, '🎼'),

        buildTwemote('scissors', `activities/scissors.png`, '✂'),
        buildTwemote('straight_ruler', `activities/straight_ruler.png`, '📏'),
        buildTwemote('microscope', `activities/microscope.png`, '🔬'),
        buildTwemote('hammer_and_pick', `activities/hammer_and_pick.png`, '⚒'),
        buildTwemote('hammer_and_wrench', `activities/hammer_and_wrench.png`, '🛠'),
        buildTwemote('hammer', `activities/hammer.png`, '🔨'),
        buildTwemote('axe', `activities/axe.png`, '🪓'),
        buildTwemote('pick', `activities/pick.png`, '⛏'),
        buildTwemote('carpentry_saw', `activities/carpentry_saw.png`, '🪚'),
        buildTwemote('wrench', `activities/wrench.png`, '🔧'),
        buildTwemote('screwdriver', `activities/screwdriver.png`, '🪛'),

        buildTwemote('toilet', `activities/toilet.png`, '🚽'),
        buildTwemote('shower', `activities/shower.png`, '🚿'),
        buildTwemote('bathtub', `activities/bathtub.png`, '🛁'),
        buildTwemote('razor', `activities/razor.png`, '🪒'),
        buildTwemote('toothbrush', `activities/toothbrush.png`, '🪥'),
        buildTwemote('cigarette', `activities/cigarette.png`, '🚬'),
        buildTwemote('broom', `activities/broom.png`, '🧹'),
        buildTwemote('bucket', `activities/bucket.png`, '🪣'),
        buildTwemote('plunger', `activities/plunger.png`, '🪠'),
        buildTwemote('pill', `activities/pill.png`, '💊'),
        buildTwemote('adhesive_bandage', `activities/adhesive_bandage.png`, '🩹'),
      ];
    case 'SYMBOLS':
      return [
        buildTwemote('heart', `symbols/heart.png`, '❤'),
        buildTwemote('orange_hear', `symbols/orange_hear.png`, '🧡'),
        buildTwemote('yellow_heart', `symbols/yellow_heart.png`, '💛'),
        buildTwemote('green_heart', `symbols/green_heart.png`, '💚'),
        buildTwemote('blue_heart', `symbols/blue_heart.png`, '💙'),
        buildTwemote('purple_heart', `symbols/purple_heart.png`, '💜'),
        buildTwemote('brown_heart', `symbols/brown_heart.png`, '🤎'),
        buildTwemote('black_heart', `symbols/black_heart.png`, '🖤'),
        buildTwemote('white_heart', `symbols/white_heart.png`, '🤍'),
        buildTwemote('growing_heart', `symbols/growing_heart.png`, '💗'),

        buildTwemote('sparkling_heart', `symbols/sparkling_heart.png`, '💖'),
        buildTwemote('heart_with_ribbon', `symbols/heart_with_ribbon.png`, '💝'),
        buildTwemote('mending_heart', `symbols/mending_heart.png`, '❤️‍🩹'),
        buildTwemote('broken_heart', `symbols/broken_heart.png`, '💔'),
        buildTwemote('beating_heart', `symbols/beating_heart.png`, '💓'),
        buildTwemote('heart_with_arrow', `symbols/heart_with_arrow.png`, '💘'),
        buildTwemote('revolving_hearts', `symbols/revolving_hearts.png`, '💞'),
        buildTwemote('two_hearts', `symbols/two_hearts.png`, '💕'),
        buildTwemote('heart_exclamation', `symbols/heart_exclamation.png`, '❣'),
        buildTwemote('pixel_heart', `symbols/pixel_heart.png`, ':pixel_heart:'),
        buildTwemote('heart_on_fire', `symbols/heart_on_fire.png`, '❤️‍🔥'),

        buildTwemote('star', `symbols/star.png`, '⭐'),
        buildTwemote('glowing_star', `symbols/glowing_star.png`, '🌟'),
        buildTwemote('sun', `symbols/sun.png`, '☀'),
        buildTwemote('first_quarter_moon_face', `symbols/first_quarter_moon_face.png`, '🌛'),
        buildTwemote('ringed_planet', `symbols/ringed_planet.png`, '🪐'),
        buildTwemote('rainbow', `symbols/rainbow.png`, '🌈'),
        buildTwemote('snowflake', `symbols/snowflake.png`, '❄'),
        buildTwemote('cyclone', `symbols/cyclone.png`, '🌀'),
        buildTwemote('high_voltage', `symbols/high_voltage.png`, '⚡'),
        buildTwemote('fire', `symbols/fire.png`, '🔥'),
        buildTwemote('comet', `symbols/comet.png`, '☄'),

        buildTwemote('recycling', `symbols/recycling.png`, '♻'),
        buildTwemote('hundred_points', `symbols/hundred_points.png`, '💯'),
        buildTwemote('anger_symbol', `symbols/anger_symbol.png`, '💢'),
        buildTwemote('speech_balloon', `symbols/speech_balloon.png`, '💬'),
        buildTwemote('thought_balloon', `symbols/thought_balloon.png`, '💭'),
        buildTwemote('right_anger_bubble', `symbols/right_anger_bubble.png`, '🗯'),
        buildTwemote('zzz', `symbols/zzz.png`, '💤'),
        buildTwemote('collision', `symbols/collision.png`, '💥'),

        buildTwemote('warning', `symbols/warning.png`, '⚠'),
        buildTwemote('no_entry', `symbols/no_entry.png`, '⛔'),
        buildTwemote('prohibited', `symbols/prohibited.png`, '🚫'),
        buildTwemote('kiss_mark', `symbols/kiss_mark.png`, '💋'),
      ];
    case 'FOOD':
      return [
        buildTwemote('grapes', `food/grapes.png`, '🍇'),
        buildTwemote('melon', `food/melon.png`, '🍈'),
        buildTwemote('watermelon', `food/watermelon.png`, '🍉'),
        buildTwemote('tangerine', `food/tangerine.png`, '🍊'),
        buildTwemote('pineapple', `food/pineapple.png`, '🍍'),

        buildTwemote('banana', `food/banana.png`, '🍌'),
        buildTwemote('lemon', `food/lemon.png`, '🍋'),

        buildTwemote('mango', `food/mango.png`, '🥭'),
        buildTwemote('red_apple', `food/red_apple.png`, '🍎'),
        buildTwemote('green_apple', `food/green_apple.png`, '🍏'),
        buildTwemote('pear', `food/pear.png`, '🍐'),
        buildTwemote('peach', `food/peach.png`, '🍑'),
        buildTwemote('cherries', `food/cherries.png`, '🍒'),
        buildTwemote('strawberry', `food/strawberry.png`, '🍓'),
        buildTwemote('blueberries', `food/blueberries.png`, '🫐'),

        buildTwemote('kiwi_fruit', `food/kiwi_fruit.png`, '🥝'),
        buildTwemote('tomato', `food/tomato.png`, '🍅'),
        buildTwemote('olive', `food/olive.png`, '🫒'),
        buildTwemote('coconut', `food/coconut.png`, '🥥'),
        buildTwemote('avocado', `food/avocado.png`, '🥑'),
        buildTwemote('potato', `food/potato.png`, '🥔'),
        buildTwemote('carrot', `food/carrot.png`, '🥕'),
        buildTwemote('ear_of_corn', `food/ear_of_corn.png`, '🌽'),
        buildTwemote('hot_pepper', `food/hot_pepper.png`, '🌶'),
        buildTwemote('bell_pepper', `food/bell_pepper.png`, '🫑'),
        buildTwemote('cucumber', `food/cucumber.png`, '🥒'),
        buildTwemote('leafy_green', `food/leafy_green.png`, '🥬'),
        buildTwemote('broccoli', `food/broccoli.png`, '🥦'),
        buildTwemote('garlic', `food/garlic.png`, '🧄'),
        buildTwemote('onion', `food/onion.png`, '🧅'),
        buildTwemote('peanuts', `food/peanuts.png`, '🥜'),
        buildTwemote('chestnut', `food/chestnut.png`, '🌰'),
        buildTwemote('beans', `food/beans.png`, '🫘'),
        buildTwemote('bread', `food/bread.png`, '🍞'),
        buildTwemote('croissant', `food/croissant.png`, '🥐'),
        buildTwemote('flatbread', `food/flatbread.png`, '🫓'),
        buildTwemote('pretzel', `food/pretzel.png`, '🥨'),
        buildTwemote('bagel', `food/bagel.png`, '🥯'),
        buildTwemote('pancakes', `food/pancakes.png`, '🥞'),
        buildTwemote('waffle', `food/waffle.png`, '🧇'),
        buildTwemote('cheese_wedge', `food/cheese_wedge.png`, '🧀'),
        buildTwemote('meat_on_bone', `food/meat_on_bone.png`, '🍖'),
        buildTwemote('poultry_leg', `food/poultry_leg.png`, '🍗'),
        buildTwemote('cut_of_meat', `food/cut_of_meat.png`, '🥩'),
        buildTwemote('bacon', `food/bacon.png`, '🥓'),
        buildTwemote('hamburger', `food/hamburger.png`, '🍔'),
        buildTwemote('french_fries', `food/french_fries.png`, '🍟'),
        buildTwemote('pizza', `food/pizza.png`, '🍕'),

        buildTwemote('hot_dog', `food/hot_dog.png`, '🌭'),
        buildTwemote('sandwich', `food/sandwich.png`, '🥪'),
        buildTwemote('taco', `food/taco.png`, '🌮'),
        buildTwemote('burrito', `food/burrito.png`, '🌯'),
        buildTwemote('tamale', `food/tamale.png`, '🫔'),
        buildTwemote('stuffed_flatbread', `food/stuffed_flatbread.png`, '🥙'),
        buildTwemote('falafel', `food/falafel.png`, '🧆'),
        buildTwemote('egg', `food/egg.png`, '🥚'),
        buildTwemote('cooking', `food/cooking.png`, '🍳'),
        buildTwemote('shallow_pan_of_food', `food/shallow_pan_of_food.png`, '🥘'),
        buildTwemote('pot_of_food', `food/pot_of_food.png`, '🍲'),
        buildTwemote('fondue', `food/fondue.png`, '🫕'),
        buildTwemote('bowl_with_spoon', `food/bowl_with_spoon.png`, '🥣'),
        buildTwemote('green_salad', `food/green_salad.png`, '🥗'),
        buildTwemote('popcorn', `food/popcorn.png`, '🍿'),
        buildTwemote('butter', `food/butter.png`, '🧈'),
        buildTwemote('salt', `food/salt.png`, '🧂'),
        buildTwemote('bento_box', `food/bento_box.png`, '🍱'),
        buildTwemote('rice_cracker', `food/rice_cracker.png`, '🍘'),
        buildTwemote('rice_ball', `food/rice_ball.png`, '🍙'),
        buildTwemote('cooked_rice', `food/cooked_rice.png`, '🍚'),
        buildTwemote('curry_rice', `food/curry_rice.png`, '🍛'),
        buildTwemote('steaming_bowl', `food/steaming_bowl.png`, '🍜'),
        buildTwemote('spaghetti', `food/spaghetti.png`, '🍝'),
        buildTwemote('roasted_sweet_potato', `food/roasted_sweet_potato.png`, '🍠'),
        buildTwemote('oden', `food/oden.png`, '🍢'),
        buildTwemote('sushi', `food/sushi.png`, '🍣'),
        buildTwemote('fried_shrimp', `food/fried_shrimp.png`, '🍤'),
        buildTwemote('fish_cake_with_swirl', `food/fish_cake_with_swirl.png`, '🍥'),
        buildTwemote('moon_cake', `food/moon_cake.png`, '🥮'),
        buildTwemote('dango', `food/dango.png`, '🍡'),
        buildTwemote('dumpling', `food/dumpling.png`, '🥟'),
        buildTwemote('fortune_cookie', `food/fortune_cookie.png`, '🥠'),
        buildTwemote('soft_ice_cream', `food/soft_ice_cream.png`, '🍦'),
        buildTwemote('shaved_ice', `food/shaved_ice.png`, '🍧'),
        buildTwemote('ice_cream', `food/ice_cream.png`, '🍨'),

        buildTwemote('doughnut', `food/doughnut.png`, '🍩'),
        buildTwemote('cookie', `food/cookie.png`, '🍪'),
        buildTwemote('birthday_cake', `food/birthday_cake.png`, '🎂'),
        buildTwemote('shortcake', `food/shortcake.png`, '🍰'),
        buildTwemote('cupcake', `food/cupcake.png`, '🧁'),
        buildTwemote('pie', `food/pie.png`, '🥧'),
        buildTwemote('chocolate_bar', `food/chocolate_bar.png`, '🍫'),
        buildTwemote('lollipop', `food/lollipop.png`, '🍭'),
        buildTwemote('custard', `food/custard.png`, '🍮'),
        buildTwemote('baby_bottle', `food/baby_bottle.png`, '🍼'),
        buildTwemote('glass_of_milk', `food/glass_of_milk.png`, '🥛'),
        buildTwemote('hot_beverage', `food/hot_beverage.png`, '☕'),
        buildTwemote('teacup_without_handle', `food/teacup_without_handle.png`, '🍵'),
        buildTwemote('sake', `food/sake.png`, '🍶'),
        buildTwemote('bottle_with_popping_cork', `food/bottle_with_popping_cork.png`, '🍾'),

        buildTwemote('wine_glass', `food/wine_glass.png`, '🍷'),
        buildTwemote('cocktail_glass', `food/cocktail_glass.png`, '🍸'),
        buildTwemote('tropical_drink', `food/tropical_drink.png`, '🍹'),
        buildTwemote('beer_mug', `food/beer_mug.png`, '🍺'),
        buildTwemote('clinking_beer_mugs', `food/clinking_beer_mugs.png`, '🍻'),
        buildTwemote('clinking_glasses', `food/clinking_glasses.png`, '🥂'),
        buildTwemote('tumbler_glass', `food/tumbler_glass.png`, '🥃'),
        buildTwemote('cup_with_straw', `food/cup_with_straw.png`, '🥤'),
        buildTwemote('bubble_tea', `food/bubble_tea.png`, '🧋'),
        buildTwemote('beverage_box', `food/beverage_box.png`, '🧃'),
        buildTwemote('mate', `food/mate.png`, '🧉'),
        buildTwemote('fork_and_knife', `food/fork_and_knife.png`, '🍴'),
        buildTwemote('chopsticks', `food/chopsticks.png`, '🥢'),
      ];
    case 'FLAGS':
      return [
        buildTwemote('flag_ascension_island', `flags/flag_ascension_island.png`, '🇦🇨'),

        buildTwemote('flag_andorra', `flags/flag_andorra.png`, '🇦🇩'),
        buildTwemote('flag_united_arab_emirates', `flags/flag_united_arab_emirates.png`, '🇦🇪'),
        buildTwemote('flag_afghanistan', `flags/flag_afghanistan.png`, '🇦🇫'),

        buildTwemote('flag_antigua_barbuda', `flags/flag_antigua_barbuda.png`, '🇦🇬'),
        buildTwemote('flag_anguilla', `flags/flag_anguilla.png`, '🇦🇮'),
        buildTwemote('flag_albania', `flags/flag_albania.png`, '🇦🇱'),
        buildTwemote('flag_armenia', `flags/flag_armenia.png`, '🇦🇲'),
        buildTwemote('flag_angola', `flags/flag_angola.png`, '🇦🇴'),
        buildTwemote('flag_antarctica', `flags/flag_antarctica.png`, '🇦🇶'),
        buildTwemote('flag_argentina', `flags/flag_argentina.png`, '🇦🇷'),

        buildTwemote('flag_american_samoa', `flags/flag_american_samoa.png`, '🇦🇸'),
        buildTwemote('flag_austria', `flags/flag_austria.png`, '🇦🇹'),
        buildTwemote('flag_australia', `flags/flag_australia.png`, '🇦🇺'),
        buildTwemote('flag_aruba', `flags/flag_aruba.png`, '🇦🇼'),
        buildTwemote('flag_aland_islands', `flags/flag_aland_islands.png`, '🇦🇽'),
        buildTwemote('flag_azerbaijan', `flags/flag_azerbaijan.png`, '🇦🇿'),
        buildTwemote('flag_bosnia_herzegovina', `flags/flag_bosnia_herzegovina.png`, '🇧🇦'),

        buildTwemote('flag_barbados', `flags/flag_barbados.png`, '🇧🇧'),
        buildTwemote('flag_bangladesh', `flags/flag_bangladesh.png`, '🇧🇩'),
        buildTwemote('flag_belgium', `flags/flag_belgium.png`, '🇧🇪'),
        buildTwemote('flag_burkina_faso', `flags/flag_burkina_faso.png`, '🇧🇫'),
        buildTwemote('flag_bulgaria', `flags/flag_bulgaria.png`, '🇧🇬'),
        buildTwemote('flag_bahrain', `flags/flag_bahrain.png`, '🇧🇭'),
        buildTwemote('flag_burundi', `flags/flag_burundi.png`, '🇧🇮'),

        buildTwemote('flag_benin', `flags/flag_benin.png`, '🇧🇯'),
        buildTwemote('flag_st_barthelemy', `flags/flag_st_barthelemy.png`, '🇧🇱'),
        buildTwemote('flag_bermuda', `flags/flag_bermuda.png`, '🇧🇲'),
        buildTwemote('flag_brunei', `flags/flag_brunei.png`, '🇧🇳'),
        buildTwemote('flag_bolivia', `flags/flag_bolivia.png`, '🇧🇴'),
        buildTwemote('flag_caribbean_netherlands', `flags/flag_caribbean_netherlands.png`, '🇧🇶'),
        buildTwemote('flag_brazil', `flags/flag_brazil.png`, '🇧🇷'),

        buildTwemote('flag_bahamas', `flags/flag_bahamas.png`, '🇧🇸'),
        buildTwemote('flag_bhutan', `flags/flag_bhutan.png`, '🇧🇹'),
        buildTwemote('flag_bouvet_island', `flags/flag_bouvet_island.png`, '🇧🇻'),
        buildTwemote('flag_botswana', `flags/flag_botswana.png`, '🇧🇼'),
        buildTwemote('flag_belarus', `flags/flag_belarus.png`, '🇧🇾'),
        buildTwemote('flag_belize', `flags/flag_belize.png`, '🇧🇿'),
        buildTwemote('flag_canada', `flags/flag_canada.png`, '🇨🇦'),

        buildTwemote('flag_cocos_keeling_islands', `flags/flag_cocos_keeling_islands.png`, '🇨🇨'),
        buildTwemote('flag_congo_kinshasa', `flags/flag_congo_kinshasa.png`, '🇨🇩'),
        buildTwemote('flag_central_african_republic', `flags/flag_central_african_republic.png`, '🇨🇫'),
        buildTwemote('flag_congo_brazzaville', `flags/flag_congo_brazzaville.png`, '🇨🇬'),
        buildTwemote('flag_switzerland', `flags/flag_switzerland.png`, '🇨🇭'),
        buildTwemote('flag_cote_divoire', `flags/flag_cote_divoire.png`, '🇨🇮'),
        buildTwemote('flag_cook_islands', `flags/flag_cook_islands.png`, '🇨🇰'),

        buildTwemote('flag_chile', `flags/flag_chile.png`, '🇨🇱'),
        buildTwemote('flag_cameroon', `flags/flag_cameroon.png`, '🇨🇲'),
        buildTwemote('flag_china', `flags/flag_china.png`, '🇨🇳'),
        buildTwemote('flag_colombia', `flags/flag_colombia.png`, '🇨🇴'),
        buildTwemote('flag_clipperton_island', `flags/flag_clipperton_island.png`, '🇨🇵'),
        buildTwemote('flag_costa_rica', `flags/flag_costa_rica.png`, '🇨🇷'),
        buildTwemote('flag_cuba', `flags/flag_cuba.png`, '🇨🇺'),

        buildTwemote('flag_cape_verde', `flags/flag_cape_verde.png`, '🇨🇻'),
        buildTwemote('flag_curacao', `flags/flag_curacao.png`, '🇨🇼'),
        buildTwemote('flag_christmas_island', `flags/flag_christmas_island.png`, '🇨🇽'),
        buildTwemote('flag_cyprus', `flags/flag_cyprus.png`, '🇨🇾'),
        buildTwemote('flag_czechia', `flags/flag_czechia.png`, '🇨🇿'),
        buildTwemote('flag_germany', `flags/flag_germany.png`, '🇩🇪'),
        buildTwemote('flag_diego_garcia', `flags/flag_diego_garcia.png`, '🇩🇬'),

        buildTwemote('flag_djibouti', `flags/flag_djibouti.png`, '🇩🇯'),
        buildTwemote('flag_denmark', `flags/flag_denmark.png`, '🇩🇰'),
        buildTwemote('flag_dominica', `flags/flag_dominica.png`, '🇩🇲'),
        buildTwemote('flag_dominican_republic', `flags/flag_dominican_republic.png`, '🇩🇴'),
        buildTwemote('flag_algeria', `flags/flag_algeria.png`, '🇩🇿'),
        buildTwemote('flag_ceuta_melilla', `flags/flag_ceuta_melilla.png`, '🇪🇦'),
        buildTwemote('flag_ecuador', `flags/flag_ecuador.png`, '🇪🇨'),

        buildTwemote('flag_estonia', `flags/flag_estonia.png`, '🇪🇪'),
        buildTwemote('flag_egypt', `flags/flag_egypt.png`, '🇪🇬'),
        buildTwemote('flag_western_sahara', `flags/flag_western_sahara.png`, '🇪🇭'),
        buildTwemote('flag_eritrea', `flags/flag_eritrea.png`, '🇪🇷'),
        buildTwemote('flag_spain', `flags/flag_spain.png`, '🇪🇸'),
        buildTwemote('flag_ethiopia', `flags/flag_ethiopia.png`, '🇪🇹'),

        buildTwemote('flag_finland', `flags/flag_finland.png`, '🇫🇮'),
        buildTwemote('flag_fiji', `flags/flag_fiji.png`, '🇫🇯'),
        buildTwemote('flag_falkland_islands', `flags/flag_falkland_islands.png`, '🇫🇰'),
        buildTwemote('flag_micronesia', `flags/flag_micronesia.png`, '🇫🇲'),
        buildTwemote('flag_faroe_islands', `flags/flag_faroe_islands.png`, '🇫🇴'),
        buildTwemote('flag_france', `flags/flag_france.png`, '🇫🇷'),
        buildTwemote('flag_gabon', `flags/flag_gabon.png`, '🇬🇦'),

        buildTwemote('flag_united_kingdom', `flags/flag_united_kingdom.png`, '🇬🇧'),
        buildTwemote('flag_grenada', `flags/flag_grenada.png`, '🇬🇩'),
        buildTwemote('flag_georgia', `flags/flag_georgia.png`, '🇬🇪'),
        buildTwemote('flag_french_guiana', `flags/flag_french_guiana.png`, '🇬🇫'),
        buildTwemote('flag_guernsey', `flags/flag_guernsey.png`, '🇬🇬'),
        buildTwemote('flag_ghana', `flags/flag_ghana.png`, '🇬🇭'),
        buildTwemote('flag_gibraltar', `flags/flag_gibraltar.png`, '🇬🇮'),

        buildTwemote('flag_greenland', `flags/flag_greenland.png`, '🇬🇱'),
        buildTwemote('flag_gambia', `flags/flag_gambia.png`, '🇬🇲'),
        buildTwemote('flag_guinea', `flags/flag_guinea.png`, '🇬🇳'),
        buildTwemote('flag_guadeloupe', `flags/flag_guadeloupe.png`, '🇬🇵'),
        buildTwemote('flag_equatorial_guinea', `flags/flag_equatorial_guinea.png`, '🇬🇶'),
        buildTwemote('flag_greece', `flags/flag_greece.png`, '🇬🇷'),
        buildTwemote(
          'flag_south_georgia_south_sandwich_islands',
          `flags/flag_south_georgia_south_sandwich_islands.png`,
          '🇬🇸'
        ),

        buildTwemote('flag_guatemala', `flags/flag_guatemala.png`, '🇬🇹'),
        buildTwemote('flag_guam', `flags/flag_guam.png`, '🇬🇺'),
        buildTwemote('flag_guinea_bissau', `flags/flag_guinea_bissau.png`, '🇬🇼'),
        buildTwemote('flag_guyana', `flags/flag_guyana.png`, '🇬🇾'),
        buildTwemote('flag_hong_kong_sar_china', `flags/flag_hong_kong_sar_china.png`, '🇭🇰'),
        buildTwemote('flag_heard_mcdonald_islands', `flags/flag_heard_mcdonald_islands.png`, '🇭🇲'),
        buildTwemote('flag_honduras', `flags/flag_honduras.png`, '🇭🇳'),

        buildTwemote('flag_croatia', `flags/flag_croatia.png`, '🇭🇷'),
        buildTwemote('flag_haiti', `flags/flag_haiti.png`, '🇭🇹'),
        buildTwemote('flag_hungary', `flags/flag_hungary.png`, '🇭🇺'),
        buildTwemote('flag_canary_islands', `flags/flag_canary_islands.png`, '🇮🇨'),
        buildTwemote('flag_indonesia', `flags/flag_indonesia.png`, '🇮🇩'),
        buildTwemote('flag_ireland', `flags/flag_ireland.png`, '🇮🇪'),
        buildTwemote('flag_israel', `flags/flag_israel.png`, '🇮🇱'),

        buildTwemote('flag_isle_of_man', `flags/flag_isle_of_man.png`, '🇮🇲'),
        buildTwemote('flag_india', `flags/flag_india.png`, '🇮🇳'),
        buildTwemote('flag_british_indian_ocean_territory', `flags/flag_british_indian_ocean_territory.png`, '🇮🇴'),
        buildTwemote('flag_iraq', `flags/flag_iraq.png`, '🇮🇶'),
        buildTwemote('flag_iran', `flags/flag_iran.png`, '🇮🇷'),
        buildTwemote('flag_iceland', `flags/flag_iceland.png`, '🇮🇸'),
        buildTwemote('flag_italy', `flags/flag_italy.png`, '🇮🇹'),

        buildTwemote('flag_jersey', `flags/flag_jersey.png`, '🇯🇪'),
        buildTwemote('flag_jamaica', `flags/flag_jamaica.png`, '🇯🇲'),
        buildTwemote('flag_jordan', `flags/flag_jordan.png`, '🇯🇴'),
        buildTwemote('flag_japan', `flags/flag_japan.png`, '🇯🇵'),
        buildTwemote('flag_kenya', `flags/flag_kenya.png`, '🇰🇪'),
        buildTwemote('flag_kyrgyzstan', `flags/flag_kyrgyzstan.png`, '🇰🇬'),
        buildTwemote('flag_cambodia', `flags/flag_cambodia.png`, '🇰🇭'),

        buildTwemote('flag_kiribati', `flags/flag_kiribati.png`, '🇰🇮'),
        buildTwemote('flag_comoros', `flags/flag_comoros.png`, '🇰🇲'),
        buildTwemote('flag_st_kitts_nevis', `flags/flag_st_kitts_nevis.png`, '🇰🇳'),
        buildTwemote('flag_north_korea', `flags/flag_north_korea.png`, '🇰🇵'),
        buildTwemote('flag_south_korea', `flags/flag_south_korea.png`, '🇰🇷'),
        buildTwemote('flag_kuwait', `flags/flag_kuwait.png`, '🇰🇼'),
        buildTwemote('flag_cayman_islands', `flags/flag_cayman_islands.png`, '🇰🇾'),

        buildTwemote('flag_kazakhstan', `flags/flag_kazakhstan.png`, '🇰🇿'),
        buildTwemote('flag_laos', `flags/flag_laos.png`, '🇱🇦'),
        buildTwemote('flag_lebanon', `flags/flag_lebanon.png`, '🇱🇧'),
        buildTwemote('flag_st_lucia', `flags/flag_st_lucia.png`, '🇱🇨'),
        buildTwemote('flag_liechtenstein', `flags/flag_liechtenstein.png`, '🇱🇮'),
        buildTwemote('flag_sri_lanka', `flags/flag_sri_lanka.png`, '🇱🇰'),
        buildTwemote('flag_liberia', `flags/flag_liberia.png`, '🇱🇷'),

        buildTwemote('flag_lesotho', `flags/flag_lesotho.png`, '🇱🇸'),
        buildTwemote('flag_lithuania', `flags/flag_lithuania.png`, '🇱🇹'),
        buildTwemote('flag_luxembourg', `flags/flag_luxembourg.png`, '🇱🇺'),
        buildTwemote('flag_latvia', `flags/flag_latvia.png`, '🇱🇻'),
        buildTwemote('flag_libya', `flags/flag_libya.png`, '🇱🇾'),
        buildTwemote('flag_morocco', `flags/flag_morocco.png`, '🇲🇦'),
        buildTwemote('flag_monaco', `flags/flag_monaco.png`, '🇲🇨'),

        buildTwemote('flag_moldova', `flags/flag_moldova.png`, '🇲🇩'),
        buildTwemote('flag_montenegro', `flags/flag_montenegro.png`, '🇲🇪'),
        buildTwemote('flag_st_martin', `flags/flag_st_martin.png`, '🇲🇫'),
        buildTwemote('flag_madagascar', `flags/flag_madagascar.png`, '🇲🇬'),
        buildTwemote('flag_marshall_islands', `flags/flag_marshall_islands.png`, '🇲🇭'),
        buildTwemote('flag_north_macedonia', `flags/flag_north_macedonia.png`, '🇲🇰'),
        buildTwemote('flag_mali', `flags/flag_mali.png`, '🇲🇱'),

        buildTwemote('flag_myanmar_burma', `flags/flag_myanmar_burma.png`, '🇲🇲'),
        buildTwemote('flag_mongolia', `flags/flag_mongolia.png`, '🇲🇳'),
        buildTwemote('flag_macao_sar_china', `flags/flag_macao_sar_china.png`, '🇲🇴'),
        buildTwemote('flag_northern_mariana_islands', `flags/flag_northern_mariana_islands.png`, '🇲🇵'),
        buildTwemote('flag_martinique', `flags/flag_martinique.png`, '🇲🇶'),
        buildTwemote('flag_mauritania', `flags/flag_mauritania.png`, '🇲🇷'),
        buildTwemote('flag_montserrat', `flags/flag_montserrat.png`, '🇲🇸'),

        buildTwemote('flag_malta', `flags/flag_malta.png`, '🇲🇹'),
        buildTwemote('flag_mauritius', `flags/flag_mauritius.png`, '🇲🇺'),
        buildTwemote('flag_maldives', `flags/flag_maldives.png`, '🇲🇻'),
        buildTwemote('flag_malawi', `flags/flag_malawi.png`, '🇲🇼'),
        buildTwemote('flag_mexico', `flags/flag_mexico.png`, '🇲🇽'),
        buildTwemote('flag_malaysia', `flags/flag_malaysia.png`, '🇲🇾'),
        buildTwemote('flag_mozambique', `flags/flag_mozambique.png`, '🇲🇿'),

        buildTwemote('flag_namibia', `flags/flag_namibia.png`, '🇳🇦'),
        buildTwemote('flag_new_caledonia', `flags/flag_new_caledonia.png`, '🇳🇨'),
        buildTwemote('flag_niger', `flags/flag_niger.png`, '🇳🇪'),
        buildTwemote('flag_norfolk_island', `flags/flag_norfolk_island.png`, '🇳🇫'),
        buildTwemote('flag_nigeria', `flags/flag_nigeria.png`, '🇳🇬'),
        buildTwemote('flag_nicaragua', `flags/flag_nicaragua.png`, '🇳🇮'),
        buildTwemote('flag_netherlands', `flags/flag_netherlands.png`, '🇳🇱'),

        buildTwemote('flag_norway', `flags/flag_norway.png`, '🇳🇴'),
        buildTwemote('flag_nepal', `flags/flag_nepal.png`, '🇳🇵'),
        buildTwemote('flag_nauru', `flags/flag_nauru.png`, '🇳🇷'),
        buildTwemote('flag_niue', `flags/flag_niue.png`, '🇳🇺'),
        buildTwemote('flag_new_zealand', `flags/flag_new_zealand.png`, '🇳🇿'),
        buildTwemote('flag_oman', `flags/flag_oman.png`, '🇴🇲'),
        buildTwemote('flag_panama', `flags/flag_panama.png`, '🇵🇦'),

        buildTwemote('flag_peru', `flags/flag_peru.png`, '🇵🇪'),
        buildTwemote('flag_french_polynesia', `flags/flag_french_polynesia.png`, '🇵🇫'),
        buildTwemote('flag_papua_new_guinea', `flags/flag_papua_new_guinea.png`, '🇵🇬'),
        buildTwemote('flag_philippines', `flags/flag_philippines.png`, '🇵🇭'),
        buildTwemote('flag_pakistan', `flags/flag_pakistan.png`, '🇵🇰'),
        buildTwemote('flag_poland', `flags/flag_poland.png`, '🇵🇱'),
        buildTwemote('flag_st_pierre_miquelon', `flags/flag_st_pierre_miquelon.png`, '🇵🇲'),

        buildTwemote('flag_pitcairn_islands', `flags/flag_pitcairn_islands.png`, '🇵🇳'),
        buildTwemote('flag_puerto_rico', `flags/flag_puerto_rico.png`, '🇵🇷'),
        buildTwemote('flag_palestinian_territories', `flags/flag_palestinian_territories.png`, '🇵🇸'),
        buildTwemote('flag_portugal', `flags/flag_portugal.png`, '🇵🇹'),
        buildTwemote('flag_palau', `flags/flag_palau.png`, '🇵🇼'),
        buildTwemote('flag_paraguay', `flags/flag_paraguay.png`, '🇵🇾'),
        buildTwemote('flag_qatar', `flags/flag_qatar.png`, '🇶🇦'),

        buildTwemote('flag_reunion', `flags/flag_reunion.png`, '🇷🇪'),
        buildTwemote('flag_romania', `flags/flag_romania.png`, '🇷🇴'),
        buildTwemote('flag_serbia', `flags/flag_serbia.png`, '🇷🇸'),
        buildTwemote('flag_russia', `flags/flag_russia.png`, '🇷🇺'),
        buildTwemote('flag_rwanda', `flags/flag_rwanda.png`, '🇷🇼'),
        buildTwemote('flag_saudi_arabia', `flags/flag_saudi_arabia.png`, '🇸🇦'),
        buildTwemote('flag_solomon_islands', `flags/flag_solomon_islands.png`, '🇸🇧'),

        buildTwemote('flag_seychelles', `flags/flag_seychelles.png`, '🇸🇨'),
        buildTwemote('flag_sudan', `flags/flag_sudan.png`, '🇸🇩'),
        buildTwemote('flag_sweden', `flags/flag_sweden.png`, '🇸🇪'),
        buildTwemote('flag_singapore', `flags/flag_singapore.png`, '🇸🇬'),
        buildTwemote('flag_st_helena', `flags/flag_st_helena.png`, '🇸🇭'),
        buildTwemote('flag_slovenia', `flags/flag_slovenia.png`, '🇸🇮'),
        buildTwemote('flag_svalbard_jan_mayen', `flags/flag_svalbard_jan_mayen.png`, '🇸🇯'),

        buildTwemote('flag_slovakia', `flags/flag_slovakia.png`, '🇸🇰'),
        buildTwemote('flag_sierra_leone', `flags/flag_sierra_leone.png`, '🇸🇱'),
        buildTwemote('flag_san_marino', `flags/flag_san_marino.png`, '🇸🇲'),
        buildTwemote('flag_senegal', `flags/flag_senegal.png`, '🇸🇳'),
        buildTwemote('flag_somalia', `flags/flag_somalia.png`, '🇸🇴'),
        buildTwemote('flag_suriname', `flags/flag_suriname.png`, '🇸🇷'),
        buildTwemote('flag_south_sudan', `flags/flag_south_sudan.png`, '🇸🇸'),

        buildTwemote('flag_sao_tome_principe', `flags/flag_sao_tome_principe.png`, '🇸🇹'),
        buildTwemote('flag_el_salvador', `flags/flag_el_salvador.png`, '🇸🇻'),
        buildTwemote('flag_sint_maarten', `flags/flag_sint_maarten.png`, '🇸🇽'),
        buildTwemote('flag_syria', `flags/flag_syria.png`, '🇸🇾'),
        buildTwemote('flag_eswatini', `flags/flag_eswatini.png`, '🇸🇿'),
        buildTwemote('flag_tristan_da_cunha', `flags/flag_tristan_da_cunha.png`, '🇹🇦'),
        buildTwemote('flag_turks_caicos_islands', `flags/flag_turks_caicos_islands.png`, '🇹🇨'),

        buildTwemote('flag_chad', `flags/flag_chad.png`, '🇹🇩'),
        buildTwemote('flag_french_southern_territories', `flags/flag_french_southern_territories.png`, '🇹🇫'),
        buildTwemote('flag_togo', `flags/flag_togo.png`, '🇹🇬'),
        buildTwemote('flag_thailand', `flags/flag_thailand.png`, '🇹🇭'),
        buildTwemote('flag_tajikistan', `flags/flag_tajikistan.png`, '🇹🇯'),
        buildTwemote('flag_tokelau', `flags/flag_tokelau.png`, '🇹🇰'),
        buildTwemote('flag_tibet', `flags/flag_tibet.png`, '🏴󠁣󠁮󠀵󠀴󠁿'),
        buildTwemote('flag_timor_leste', `flags/flag_timor_leste.png`, '🇹🇱'),

        buildTwemote('flag_turkmenistan', `flags/flag_turkmenistan.png`, '🇹🇲'),
        buildTwemote('flag_tunisia', `flags/flag_tunisia.png`, '🇹🇳'),
        buildTwemote('flag_tonga', `flags/flag_tonga.png`, '🇹🇴'),
        buildTwemote('flag_turkey', `flags/flag_turkey.png`, '🇹🇷'),
        buildTwemote('flag_trinidad_tobago', `flags/flag_trinidad_tobago.png`, '🇹🇹'),
        buildTwemote('flag_tuvalu', `flags/flag_tuvalu.png`, '🇹🇻'),
        buildTwemote('flag_taiwan', `flags/flag_taiwan.png`, '🇹🇼'),

        buildTwemote('flag_tanzania', `flags/flag_tanzania.png`, '🇹🇿'),
        buildTwemote('flag_ukraine', `flags/flag_ukraine.png`, '🇺🇦'),
        buildTwemote('flag_uganda', `flags/flag_uganda.png`, '🇺🇬'),
        // buildTwemote('flag_us_outlying_islands', `flags/flag_us_outlying_islands.png`, '🇺🇲'),
        buildTwemote('flag_united_states', `flags/flag_united_states.png`, '🇺🇸'),
        buildTwemote('flag_uruguay', `flags/flag_uruguay.png`, '🇺🇾'),

        buildTwemote('flag_uzbekistan', `flags/flag_uzbekistan.png`, '🇺🇿'),
        buildTwemote('flag_vatican_city', `flags/flag_vatican_city.png`, '🇻🇦'),
        buildTwemote('flag_st_vincent_grenadines', `flags/flag_st_vincent_grenadines.png`, '🇻🇨'),
        buildTwemote('flag_venezuela', `flags/flag_venezuela.png`, '🇻🇪'),
        buildTwemote('flag_british_virgin_islands', `flags/flag_british_virgin_islands.png`, '🇻🇬'),
        buildTwemote('flag_us_virgin_islands', `flags/flag_us_virgin_islands.png`, '🇻🇮'),
        buildTwemote('flag_vietnam', `flags/flag_vietnam.png`, '🇻🇳'),

        buildTwemote('flag_vanuatu', `flags/flag_vanuatu.png`, '🇻🇺'),
        buildTwemote('flag_wallis_futuna', `flags/flag_wallis_futuna.png`, '🇼🇫'),
        buildTwemote('flag_samoa', `flags/flag_samoa.png`, '🇼🇸'),
        buildTwemote('flag_kosovo', `flags/flag_kosovo.png`, '🇽🇰'),
        buildTwemote('flag_yemen', `flags/flag_yemen.png`, '🇾🇪'),
        buildTwemote('flag_mayotte', `flags/flag_mayotte.png`, '🇾🇹'),
        buildTwemote('flag_south_africa', `flags/flag_south_africa.png`, '🇿🇦'),

        buildTwemote('flag_zambia', `flags/flag_zambia.png`, '🇿🇲'),
        buildTwemote('flag_zimbabwe', `flags/flag_zimbabwe.png`, '🇿🇼'),
        buildTwemote('flag_england', `flags/flag_england.png`, '🏴󠁧󠁢󠁥󠁮󠁧󠁿'),
        buildTwemote('flag_scotland', `flags/flag_scotland.png`, '🏴󠁧󠁢󠁳󠁣󠁴󠁿'),
        buildTwemote('flag_wales', `flags/flag_wales.png`, '🏴󠁧󠁢󠁷󠁬󠁳󠁿'),

        buildTwemote('flag_european_union', `flags/flag_european_union.png`, '🇪🇺'),
        buildTwemote('flag_united_nations', `flags/flag_united_nations.png`, '🇺🇳'),

        buildTwemote('pirate_flag', `flags/pirate_flag.png`, '🏴‍☠️'),
        buildTwemote('rainbow_flag', `flags/rainbow_flag.png`, '🏳️‍🌈'),
        buildTwemote('transgender_flag', `flags/transgender_flag.png`, '🏳️‍⚧️'),
        buildTwemote('kek_flag', `flags/kek_flag.png`, ':kek_flag:'),
        buildTwemote('anarchocapitalism_flag', `flags/anarchocapitalism_flag.png`, ':anarchocapitalism_flag:'),
        buildTwemote('communism_flag', `flags/communism_flag.png`, ':communism_flag:'),
        buildTwemote('antifa_flag', `flags/antifa_flag.png`, ':antifa_flag:'),
        buildTwemote('gadsden_flag', `flags/gadsden_flag.png`, ':gadsden_flag:'),
        buildTwemote('white_flag', `flags/white_flag.png`, '🏳'),
        buildTwemote('black_flag', `flags/black_flag.png`, '🏴'),
      ];
  }
};

function buildTwemoteArray(Twemotes): Array<Object> {
  return []
    .concat(Twemotes['SMILIES'])
    .concat(Twemotes['HANDSIGNALS'])
    .concat(Twemotes['ACTIVITIES'])
    .concat(Twemotes['SYMBOLS'])
    .concat(Twemotes['NATURE'])
    .concat(Twemotes['FOOD'])
    .concat(Twemotes['FLAGS']);
}

function buildTwemoteNameArray(objectArray) {
  let res = [];
  for (let entry of objectArray) {
    // $FlowIgnore
    res.push(entry.name);
  }
  return res;
}

export const EMOTES_24px = getEmotes('24%20px', '');
export const EMOTES_36px = getEmotes('36px', '%401.5x');
export const EMOTES_48px = getEmotes('48%20px', '%402x');
export const EMOTES_72px = getEmotes('72%20px', '%403x');
export const TWEMOTES = {
  SMILIES: getTwemotes('SMILIES'),
  HANDSIGNALS: getTwemotes('HANDSIGNALS'),
  ACTIVITIES: getTwemotes('ACTIVITIES'),
  SYMBOLS: getTwemotes('SYMBOLS'),
  NATURE: getTwemotes('NATURE'),
  FOOD: getTwemotes('FOOD'),
  FLAGS: getTwemotes('FLAGS'),
};
export const TWEMOTEARRAY = buildTwemoteArray(TWEMOTES);
export const TWEMOTENAMES = buildTwemoteNameArray(TWEMOTEARRAY);
